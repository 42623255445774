import { Injectable, NgZone } from '@angular/core';
import { ErrorDialogComponent } from '../components/error-dialog/error-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CcmcApiService } from './ccmc-api.service';
import { CCMCCollateralSearchDialogComponent } from '../components/collateral-search-dialog/collateral-search-dialog.component';
import { Router } from '@angular/router';
import { navigation } from '../../app/navigation/navigation';
import { SpinnerService } from './spinner.service';
import { ActiveLosService } from './active-los.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, filter, takeUntil } from 'rxjs/operators';
import { CustomErrorHandlerService } from './custom-error-handler.service';
import { SuccessDialogComponent } from '../components/success-dialog/success-dialog.component';
import { AssetService } from './asset.service';
import { GeneralLedgerAccountingService } from './general-ledger-accounting.service';
import { Subject } from 'rxjs';
import * as AWS from 'aws-sdk';
import { APPCONSTANTS } from 'src/app/app.constants';
import { CaptureDataService } from './capture-data.service';
import { AmplifyService } from './amplify.service';
import { GlobalSearchService } from './global-search.service';
import { TargetResponseDialogComponent } from '../components/target-response-dialog/target-response-dialog.component';
import { environment } from'src/environments/environment';
import { DebitCeditCheckDialogComponent } from '../components/debit-cedit-check-dialog/debit-cedit-check-dialog.component';
const { Buffer } = require('buffer');

const MONETARY_GL_FIELD = 'MSG_005';
const CREDIT_DEBIT_FIELD = 'TR0*_0009';
const GL_AMOUNT_FIELD = 'TR0*_0004';
@Injectable({
  providedIn: 'root'
})
export class JxchangeService {
  dynamicNav: any = navigation;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  resultTitle = 'JxChange Result';
  unsubscribe: Subject<any> = new Subject();
  /**
   * Creates an instance of JxchangeService.
   * @param {MatDialog} dialog
   * @param {CcmcApiService} ccmcApiService
   * @param {Router} router
   * @param {SpinnerService} spinnerService
   * @param {ActiveLosService} activeLosService
   * @param {NgZone} zone
   * @param {HttpClient} http
   * @param {CustomErrorHandlerService} customErrorHandlerService
   * @param {AssetService} assetService
   * @memberof JxchangeService
   */
  constructor(
    private dialog: MatDialog,
    private ccmcApiService: CcmcApiService,
    public router: Router,
    private spinnerService: SpinnerService,
    private activeLosService: ActiveLosService,
    private zone: NgZone,
    private http: HttpClient,
    private customErrorHandlerService: CustomErrorHandlerService,
    private assetService: AssetService,
    private amplifyService: AmplifyService,
    private glaService: GeneralLedgerAccountingService,
    private captureDataService: CaptureDataService,
    private globalSearchService: GlobalSearchService
  ) {
    this.httpOptions.headers.append('Access-Control-Allow-Origin', '*');
    this.httpOptions.headers.append(
      'Access-Control-Allow-Methods',
      'POST, GET, OPTIONS, PUT'
    );
    this.httpOptions.headers.append('Accept', 'application/json');
  }

  /**
   * Send
   * @description Sends the loan to the jxchange communicator
   * @memberof JxchangeService
   */
  async send() {
    if (this.assetService.getSelectedAssetId() == 'sales777') {
      this.resultTitle = 'Generic Result';
    } else {
      this.resultTitle = 'JxChange Result';
    }
    this.spinnerService.setShowSpinner(true);
    let userName = await this.amplifyService.getUserName();
    const exportObject = {
      content: {
        mapping: this.ccmcApiService.mapping,
        configurations: this.ccmcApiService.configurations,
        transactions: this.ccmcApiService.transactions
      },
      user: userName,
      assetID: this.assetService.getSelectedAssetId()
    };
    // console.log(exportObject);
    this.export(exportObject).subscribe(res => {
    console.log(res);
    const parsedResult = JSON.parse(JSON.stringify(res));

    if (parsedResult.error) {
      const errorMessage = {
        message: parsedResult.error.message,
        title: this.resultTitle
      };
      const dialogRef2 = this.zone.run(() => {
        this.dialog.open(ErrorDialogComponent, {
          data: errorMessage
        });
      });
    }
    if (parsedResult.statusFlag === true) {
      const resultMessage = {
        message: parsedResult.statusMessage,
        title: this.resultTitle
      };

      const dialogRef = this.zone.run(() => {
        this.dialog.open(SuccessDialogComponent, {
          data: resultMessage
        });
      });

      this.activeLosService.activeLosService.loanBooked(
        parsedResult,
        JSON.parse(JSON.stringify(this.ccmcApiService.configurations))
      );
      this.ccmcApiService.mapping = undefined;
      this.ccmcApiService.coreSettings = undefined;
      this.ccmcApiService.conditions = undefined;
      this.ccmcApiService.mapping = undefined;
      this.ccmcApiService.supportValues = undefined;
      this.ccmcApiService.search = undefined;
      this.ccmcApiService.transactions = undefined;
      this.ccmcApiService.configurations = undefined;
      if (this.dynamicNav[1].children[1]) {
        this.dynamicNav[1].children[1].children = [];
      }
      this.dynamicNav[1].children[0].badge.title = undefined;
      if (this.activeLosService.activeLos === 'encompass' || this.activeLosService.activeLos === 'bytepro') {
        this.router.navigate(['loan-lookup']);
      } else {
        this.router.navigate(['file-selector']);
      }
    }
    if (parsedResult.statusFlag === false) {
      this.ccmcApiService.mapping = parsedResult.content.mapping;
      this.globalSearchService.initData();
      this.globalSearchService.clearData();
      this.globalSearchService.globalSearchRefresh.next(true);
      const errorMessage = {
        message: parsedResult.statusMessage,
        title: this.resultTitle
      };

      const dialogRef = this.zone.run(() => {
        this.dialog.open(ErrorDialogComponent, {
          data: errorMessage
        });
      });
    }
    });
  }

  processGLA(glaDocument: any) {
    this.spinnerService.setShowSpinner(true);
    let userName = this.amplifyService.username;
    let sortedCoreLayout = JSON.parse(JSON.stringify(glaDocument.coreLayout));
    this.glaService.sortCoreLayout(sortedCoreLayout);
    const exportObject = {
      content: {
        set: this.removeBlankAmountsFromSet(glaDocument.set, sortedCoreLayout),
        configurations: glaDocument.configurations,
        coreLayout: sortedCoreLayout
      },
      user: userName,
      assetID: this.assetService.getSelectedAssetId(),
      loanNumber: this.captureDataService.loanNumber
    };
    // console.log(JSON.stringify(exportObject));
    this.sendProcessGLA(exportObject).subscribe(res => {
      // console.log(res);
      const parsedResult = JSON.parse(JSON.stringify(res));

      if (parsedResult.error) {
        const errorMessage = {
          message: parsedResult.error.message,
          title: this.resultTitle
        };
        const dialogRef2 = this.zone.run(() => {
          this.dialog.open(ErrorDialogComponent, {
            data: errorMessage
          });
        });
        this.ccmcApiService.logExportResponse({
          assetID: this.assetService.getSelectedAssetId(),
          loanNumber: this.captureDataService.loanNumber,
          statusMessage: parsedResult.error.message,
          statusFlag: parsedResult.statusFlag
        });
      }
      if (parsedResult.statusFlag === true) {
        const resultMessage = {
          message: parsedResult.statusMessage,
          title: this.resultTitle
        };
        this.activeLosService.activeLosService.loanBooked(
          parsedResult,
          JSON.parse(JSON.stringify(glaDocument.configurations))
        );
        this.ccmcApiService.logExportResponse({
          assetID: this.assetService.getSelectedAssetId(),
          loanNumber: this.captureDataService.loanNumber,
          statusMessage: parsedResult.statusMessage,
          statusFlag: parsedResult.statusFlag
        });
        this.glaService.setGLAObject({});
        const dialogRef = this.dialog.open(SuccessDialogComponent, {
          data: resultMessage
        });
        dialogRef.afterClosed().subscribe(result => {
          if (this.activeLosService.activeLos === 'encompass' || this.activeLosService.activeLos === 'bytepro') {
            this.router.navigate(['loan-lookup']);
          } else {
            this.router.navigate(['file-selector']);
          }
        });
      }
      if (parsedResult.statusFlag === false) {
        const errorMessage = {
          message: parsedResult.statusMessage,
          title: this.resultTitle
        };

        const dialogRef = this.zone.run(() => {
          this.dialog.open(ErrorDialogComponent, {
            data: errorMessage
          });
        });
        this.ccmcApiService.logExportResponse({
          assetID: this.assetService.getSelectedAssetId(),
          loanNumber: this.captureDataService.loanNumber,
          statusMessage: parsedResult.statusMessage,
          statusFlag: parsedResult.statusFlag
        });
      }
    });
  }

  /**
   * Customer Search
   * @description performs the customers search in the jxchange communicator
   * @param {*} borrower
   * @param {*} callback
   * @memberof JxchangeService
   */
  customerSearch(borrower: any, callback: any) {
    let userName = this.amplifyService.username;
    const request = {
      content: borrower,
      assetID: this.assetService.getSelectedAssetId(),
      user: userName
    };
    // console.log(request);
    request.content.cognitoID = this.amplifyService.getCognitoId();
    this.searchCustomer(request).subscribe(res => {
      // console.log(res);
      const parsedResult = JSON.parse(JSON.stringify(res));
      // console.log(parsedResult.error);
      if (parsedResult.error) {
        const errorMessage = {
          message: JSON.stringify(parsedResult.error.message),
          title: this.resultTitle
        };

        const dialogRef = this.dialog.open(ErrorDialogComponent, {
          data: errorMessage
        });
      }
      if (parsedResult.statusFlag === true) {
        this.spinnerService.setShowSpinner(false);
        const payload = parsedResult.content;
        if (payload.length > 0) {
          const searchResult = payload;
          // console.log(searchResult);
          callback(searchResult);
        } else {
          const noResult = [
            {
              firstName: 'No Result',
              lastName: '',
              taxID: '',
              CIF: '',
              address: ''
            }
          ];
          const searchResult = noResult;
          console.log(searchResult);
          callback(searchResult);
        }
      } else if (parsedResult.statusFlag === false) {
        const errorMessage = {
          message: parsedResult.statusMessage,
          title: 'Search Error'
        };

        const dialogRef = this.dialog.open(ErrorDialogComponent, {
          data: errorMessage
        });
      }
    });
  }

  /**
   * Assign Borrowers
   * @description set the field value to the customer name
   * @param {*} party
   * @memberof JxchangeService
   */
  assignBorrowers(party: any) {
    for (let i = 0; i < party.length; i++) {
      const CIFFields = party[i].CIFFields.split(',');
      CIFFields.forEach((cf: any) => {
        const cifIndex = this.ccmcApiService.mapping.findIndex(
          (f: any) => f.fieldID === cf.trim()
        );
        this.ccmcApiService.mapping[cifIndex].fieldValue = party[i].CIF;
      });
    }
  }

  /**
   * Search Customer
   * @description calls the search customer api
   * @param {*} params
   * @returns
   * @memberof JxchangeService
   */
  searchCustomer(params: any) {
    
    // console.log(JSON.stringify(params));
    return this.http
      .post(
        `${Buffer.from(environment.environmentURL, "base64").toString()}/jack-henry/jxchange/search-customer`,
        params,
        this.httpOptions
      )
      .pipe(catchError(this.customErrorHandlerService.handleError));
  }

  /**
   * Export
   * @desciption call the export api
   * @param {*} params
   * @returns
   * @memberof JxchangeService
   */
  export(params: any) {
    
    return this.http
      .post(
        `${Buffer.from(environment.environmentURL, "base64").toString()}/jack-henry/jxchange/export-messages`,
        params,
        this.httpOptions
      )
      .pipe(catchError(this.customErrorHandlerService.handleError));
  }

  handleExport(error: any, response: any) {
    if (error) {
      const dialogRef2 = this.zone.run(() => {
        this.dialog.open(ErrorDialogComponent, {
          data: error
        });
      });
    } else {
    }
  }

  sendProcessGLA(params: any) {
    
    console.log(params);
    return this.http
      .post(
        `${Buffer.from(environment.environmentURL, "base64").toString()}/jack-henry/jxchange/process-gla`,
        params,
        this.httpOptions
      )
      .pipe(catchError(this.customErrorHandlerService.handleError));
  }

  /**
   * auto Customer Search
   * @description performs the auto customers search in the horizon communicator
   * @param {*} borrower
   * @param {*} callback
   * @memberof JxchangeService
   */
  autoCustomerSearch(borrower: any, callback: any) {
    let userName = this.amplifyService.username;
    const request = {
      content: borrower,
      assetID: this.assetService.getSelectedAssetId(),
      user: userName
    };
    // console.log('Request: \n', JSON.stringify(request));
    request.content.cognitoID = this.amplifyService.getCognitoId();
    this.searchCustomer(request).subscribe(res => {
      // console.log('Customer search response: ', res);
      const parsedResult = JSON.parse(JSON.stringify(res));
      // console.log(parsedResult);
      if (parsedResult.error) {
        const errorMessage = {
          message: JSON.stringify(parsedResult.error.message),
          title: 'JxChange Result'
        };

        const dialogRef = this.dialog.open(ErrorDialogComponent, {
          data: errorMessage
        });
      }
      if (parsedResult.statusFlag === true) {
        this.spinnerService.setShowSpinner(false);
        const payload = parsedResult.content;
        if (payload.length > 0) {
          const searchResult = payload;
          // console.log(searchResult);
          callback(searchResult);
        } else {
          const noResult = [
            {
              firstName: 'No Result',
              lastName: '',
              taxID: '',
              CIF: '',
              address: ''
            }
          ];
          const searchResult = noResult;
          // console.log(searchResult);
          callback(searchResult);
        }
      } else if (parsedResult.statusFlag === false) {
        this.spinnerService.setShowSpinner(false);
        if (
          parsedResult.statusMessage
            .toLowerCase()
            .includes('no customer record found')
        ) {
          const noResult = [
            {
              firstName: 'No Result',
              lastName: '',
              taxID: '',
              CIF: '',
              address: ''
            }
          ];
          const searchResult = noResult;
          // console.log(searchResult);
          callback(searchResult);
        } else {
          const errorMessage = {
            message: parsedResult.statusMessage,
            title: 'Search Error'
          };

          const dialogRef = this.dialog.open(ErrorDialogComponent, {
            data: errorMessage
          });
          callback(parsedResult);
        }
      }
    });
  }

  async jxchangeBalanceCheck() {
    return new Promise((resolve) => {
      const zeroPad = (num: any, places: any) => String(num).padStart(places, '0')
      // Initialize counters to zero
      let creditCounter = 0, debitCounter = 0, totalCounter = 0;
        // Initialize variables to keep track of indexes and transaction information
        let glInstance = 1;
        let paddedGLInstance = zeroPad(glInstance, 2);
        let creditDebitInstance = CREDIT_DEBIT_FIELD.replace('*', `${paddedGLInstance}`);
        let creditDebitInstanceIndex = this.ccmcApiService.mapping.findIndex(
          (msg: any) => msg.fieldID === creditDebitInstance
        );
        let glAmountInstance = GL_AMOUNT_FIELD.replace('*', `${paddedGLInstance}`);
        let glAmountInstanceIndex = this.ccmcApiService.mapping.findIndex(
          (msg: any) => msg.fieldID === glAmountInstance
        );
        let currentGLTransactionID = this.ccmcApiService.mapping[creditDebitInstanceIndex].transactionID;
        let transactionIndex = this.ccmcApiService.transactions.findIndex((transaction: any) => transaction.transactionID === currentGLTransactionID)
        let currentTransaction = this.ccmcApiService.transactions[transactionIndex];

        // Loop until there are no more transactions or fields to process
        while (creditDebitInstanceIndex > -1 && glAmountInstanceIndex > -1) {
          // Get values of the credit/debit field and the amount field
          const creditOrDebitField = this.ccmcApiService.mapping[creditDebitInstanceIndex].fieldValue.toLowerCase();
          const amountField = this.ccmcApiService.mapping[glAmountInstanceIndex].fieldValue;
          const amountValue = +amountField;
          // Add to the appropriate counter based on credit/debit value
          if (creditOrDebitField === 'debit' && currentTransaction.display) {
            debitCounter += Math.abs(amountValue)
          } else if (creditOrDebitField === 'credit' && currentTransaction.display) {
            creditCounter += Math.abs(amountValue)
          }
          // Increment instance and update indexes for next loop iteration
          glInstance++;
          paddedGLInstance = zeroPad(glInstance, 2);
          creditDebitInstance = CREDIT_DEBIT_FIELD.replace('*', `${paddedGLInstance}`);
          creditDebitInstanceIndex = this.ccmcApiService.mapping.findIndex(
            (msg: any) => msg.fieldID === creditDebitInstance
          );
          if (creditDebitInstanceIndex > -1) {
            glAmountInstance = GL_AMOUNT_FIELD.replace('*', `${paddedGLInstance}`);
            glAmountInstanceIndex = this.ccmcApiService.mapping.findIndex(
              (msg: any) => msg.fieldID === glAmountInstance
            );
            currentGLTransactionID = this.ccmcApiService.mapping[creditDebitInstanceIndex].transactionID;
          }
          if (transactionIndex > -1) {
            transactionIndex = this.ccmcApiService.transactions.findIndex((transaction: any) => transaction.transactionID === currentGLTransactionID)
            currentTransaction = this.ccmcApiService.transactions[transactionIndex];
          }
        }

        // If credits and debits don't balance, open a dialog box for confirmation
        if ((creditCounter - debitCounter !== 0) && this.ccmcApiService.configurations.showGLBalancePrompt === true) {
          totalCounter = creditCounter - debitCounter;
          const message =
            'Are you sure you want to continue with the following Debit and Credit?';
          const dialogRef2 = this.dialog.open(DebitCeditCheckDialogComponent, {
            data: {
              message: message,
              total: totalCounter,
              credit: creditCounter,
              debit: debitCounter
            },
            panelClass: 'debit-credit__dialog'
          });

          // If user confirms, return true. Otherwise return false.
          dialogRef2.afterClosed().subscribe(data => {
            if (data === true) {
              return resolve(true);
            } else {
              return resolve(false)
            }
          });
        }
        // If credits and debits balance or there are no transactions to process, exit function and return true
        else {
          return resolve(true);
        }
      
      // If value of previous field was not Y, exit function and return true
    })
  }

  getLoanNumber() {
    // console.log('get Loan number');
    let loanFieldIndex;
    if (this.assetService.getSelectedCore() === 'silverlake') {
      loanFieldIndex = this.ccmcApiService.mapping.findIndex(
        (obj: any) => obj.fieldID === 'LA01_0002'
      );
    } else if (this.assetService.getSelectedCore() === '2020') {
      let la01Index = this.ccmcApiService.mapping.findIndex(
        (obj: any) => obj.fieldID === 'LA01_0002'
      );
      let lam01Index = this.ccmcApiService.mapping.findIndex(
        (obj: any) => obj.fieldID === 'LAM01_0002'
      );
      if (la01Index > -1) {
        loanFieldIndex = la01Index;
      } else {
        loanFieldIndex = lam01Index;
      }
    } else if (this.assetService.getSelectedCore() === 'coredirector') {
      loanFieldIndex = this.ccmcApiService.mapping.findIndex(
        (obj: any) => obj.fieldID === 'LA01_0003'
      );
    }

    if (loanFieldIndex > -1) {
      // Return loan number
      // console.log(
      //   'loan number',
      //   this.ccmcApiService.mapping[loanFieldIndex].fieldValue
      // );
      return this.ccmcApiService.mapping[loanFieldIndex].fieldValue;
    }
  }

  removeBlankAmountsFromSet(set: any, targetLayout: any) {
    // Initial temp set
    let tempSet = JSON.parse(JSON.stringify(set));
    // Find Amount Index in targetlayout
    let amountIndex = targetLayout.findIndex(
      (targetItem: any) => targetItem.columnName === 'Amount'
    );
    // Set columnID
    let amountColumnID = targetLayout[amountIndex].columnID;
    // Initial cleaned transactions
    let cleanedTransactions = [];

    for (let transaction of tempSet.transactions) {
      let hasAmount = false;
      for (let col of transaction.columns) {
        // If columnId is amount and value is longer than 0 we keep
        if (col.columnID === amountColumnID && col.fieldValue.length > 0) {
          hasAmount = true;
        }
      }
      // If has amount push to new array
      if (hasAmount) {
        cleanedTransactions.push(transaction);
      }
    }
    // Reassign transactions
    tempSet.transactions = cleanedTransactions;
    // Return new set
    return tempSet;
  }
}
