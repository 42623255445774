import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CCMCConfirmDialogComponent } from 'src/@ccmc/components/confirm-dialog/confirm-dialog.component';
import { ErrorDialogComponent } from 'src/@ccmc/components/error-dialog/error-dialog.component';
import { AssetService } from 'src/@ccmc/services/asset.service';
import { CcmcApiService } from 'src/@ccmc/services/ccmc-api.service';
import { DocumentConnectorService } from 'src/@ccmc/services/doc-connector.service';
import { CCMCSelectedFieldService } from 'src/@ccmc/services/selected-field.service';
import { SpinnerService } from 'src/@ccmc/services/spinner.service';

@Component({
  selector: 'app-doc-connector-history',
  templateUrl: './doc-connector-history.component.html',
  styleUrls: ['./doc-connector-history.component.scss']
})
export class DocConnectorHistoryComponent implements OnInit {
  dcDoc: any;
  constructor(
    private dcService: DocumentConnectorService,
    private assetService: AssetService,
    private dialog: MatDialog,
    private selectedFieldService: CCMCSelectedFieldService,
    private spinnerService: SpinnerService,
    private ccmcApiService: CcmcApiService,
    private _cd: ChangeDetectorRef
  ) {}
  filterSearchText: string;
  // dataSourceChangepage: any;
  selectedLoan: any;
  private dcDocSub: Subscription;
  private documentLosSub: Subscription;
  private selectedSub: Subscription;
  private spinnerSub: Subscription;
  showSpinner: boolean;
  history: any = [];
  unsubscribe: Subject<any> = new Subject();
  displayedColumns = ['checkBox', 'loanNumber', 'date', 'status', 'processed', 'transmitted', 'pending', 'failed'];
  selectedLoanDisplayedColumns = [
    'fileName',
    'date',
    'statusCompressedandMerged',
    'statusCore',
    'statusS3'
  ];
  dataSource: any;
  selectedLoanDataSource: any;
  checkedLoans: any;
  lastEvaluatedKey: any;
  exportButtonSwitch: boolean = false;
  getHistoryResponse: any;
  @ViewChild('filterSearch') filterSearchRef: ElementRef;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  private selectedLoansToSendSub: Subscription;
  ngOnInit(): void {
    this.getData();
    this.getHistory();
    console.log('Doc Connector Document =>', this.dcDoc);
  }

  async getHistory() {
    console.log(this.assetService.getSelectedAssetId());
    this.getHistoryResponse = await this.dcService.getDocConnectorHistory(
      this.assetService.getSelectedAssetId()
    );
    console.log(this.getHistoryResponse);
    if (this.getHistoryResponse['statusFlag']) {
      if (this.getHistoryResponse['content'].Items.length > 0) {
        this.history = this.getHistoryResponse['content'].Items;
        // Set checkToSend
        for (let historyItem of this.getHistoryResponse['content'].Items) {
          historyItem.checkToSend = false;
          let historyItemFilesStatusMessageArr = historyItem.files.map((item: any) => item.statusMessage);
            if(historyItemFilesStatusMessageArr.every((item: any) => item.toLowerCase() === 'loan file exported successfully')) {
              historyItem.status = "Successful"
            } else {
              historyItem.status = "Unsuccessful"
            }   
        }
        this.dataSource = new MatTableDataSource(this.getHistoryResponse['content'].Items);
        // initializes sort
        this.dataSource.sort = this.sort;
        const sortState: Sort = {active: 'date', direction: 'desc'};
        this.sort.active = sortState.active;
        this.sort.direction = sortState.direction;
        this.sort.sortChange.emit(sortState);

        this.dataSource.sortingDataAccessor = (item: any, property: any) => {
          switch (property) {
            case 'date': return new Date(item.date);
            case 'loanNumber': return new Number(item.loanNumber);
            case 'status': return new String(item.status);
            default: return item[property];
          }
        };
        this.dataSource.paginator = this.paginator;
      } else {
        this.dataSource = new MatTableDataSource([]);
        // initializes sort
        this.dataSource.sort = this.sort;
        const sortState: Sort = {active: 'date', direction: 'desc'};
        this.sort.active = sortState.active;
        this.sort.direction = sortState.direction;
        this.sort.sortChange.emit(sortState);

        this.dataSource.sortingDataAccessor = (item: any, property: any) => {
          switch (property) {
            case 'date': return new Date(item.date);
            case 'loanNumber': return new Number(item.loanNumber);
            case 'status': return new String(item.status);
            default: return item[property];
          }
        };
        this.dataSource.paginator = this.paginator;
        const errorMessage = {
          title: 'History',
          message: `No History found for AssetID: ${this.assetService.getSelectedAssetId()}`
        };
        this.dialog.open(ErrorDialogComponent, {
          data: errorMessage
        });
      }
      if (this.getHistoryResponse['content'].LastEvaluatedKey) {
        this.lastEvaluatedKey = this.getHistoryResponse['content'].LastEvaluatedKey;
      }
    } else {
      const errorMessage = {
        title: 'History',
        message: this.getHistoryResponse['statusMessage']
      };
      const dialogRef = this.dialog.open(ErrorDialogComponent, {
        data: errorMessage
      });
    }
  }

  async getMoreHistory() {
    this.spinnerService.setShowSpinner(true);
    this.getHistoryResponse = await this.dcService.getMoreDocConnectorHistory(
      this.assetService.getSelectedAssetId(),
      this.lastEvaluatedKey
    );
    this.spinnerService.setShowSpinner(false);
    if (this.getHistoryResponse['statusFlag']) {
      if (this.getHistoryResponse['content'].Items.length > 0) {
        // Set checkToSend
        for (let historyItem of this.getHistoryResponse['content'].Items) {
          historyItem.checkToSend = false;
        }
        this.dataSource.data = [...this.dataSource.data, ...this.getHistoryResponse['content'].Items]
        // initializes sort
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      } else {
        const errorMessage = {
          title: 'History',
          message: `No more History found for AssetID: ${this.assetService.getSelectedAssetId()}`
        };
        this.dialog.open(ErrorDialogComponent, {
          data: errorMessage
        });
      }
      if (this.getHistoryResponse['content'].LastEvaluatedKey) {
        this.lastEvaluatedKey = this.getHistoryResponse['content'].LastEvaluatedKey;
      } else {
        this.lastEvaluatedKey = undefined;
      }
    } else {
      const errorMessage = {
        title: 'History',
        message: this.getHistoryResponse['statusMessage']
      };
      const dialogRef = this.dialog.open(ErrorDialogComponent, {
        data: errorMessage
      });
    }
    console.log(this.getHistoryResponse);
  }

  refreshDocConnectorHistoryTable() {
    
  }

  onSelectLoan(selected: any) {
    this.selectedLoan = selected;
    this.selectedFieldService.onHistoryLoanFieldSelected.next(selected);
    this.selectedLoanDataSource = new MatTableDataSource(selected.files);
  }

  getData() {
    this.selectedSub = this.selectedFieldService.onHistoryLoanFieldSelected
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(selected => {
        this.selectedLoan = selected;
      });
    // Subscribe to the dcDocument
    this.dcDocSub = this.dcService.dcDocument
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(documentCore => {
        if (documentCore) {
          this.dcDoc = JSON.parse(JSON.stringify(documentCore));
        }
      });
    // Subscribe to the dcDocument
    this.selectedLoansToSendSub = this.selectedFieldService.checkedHistoryLoans
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(historyLoansToSend => {
        if (historyLoansToSend) {
          console.log(historyLoansToSend);
          this.checkedLoans = JSON.parse(JSON.stringify(historyLoansToSend));
        }
      });
    this.spinnerSub = this.spinnerService.spinner
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(spinner => {
        this.showSpinner = spinner;
      });
  }

  clearSelectedLoan() {
    this.selectedFieldService.onHistoryLoanFieldSelected.next(undefined);
  }

  ngOnDestroy() {
    this.selectedFieldService.checkedHistoryLoans.next([]);
    this.unsubscribe.next(0);
    this.unsubscribe.complete();
  }

  updateCheckedLoans(event: any, historyLoan: any) {
    console.log(event);
    if (event.checked) {
      // Only allow to send one loan at a time due to long wait times for computation
      this.checkedLoans = [];
      for (let loan of this.dataSource.data) {
        loan.checkToSend = false;
      }
      historyLoan.checkToSend = true;
      this.checkedLoans.push(historyLoan);
    } else {
      let loanIndex = this.checkedLoans.findIndex(
        (loan: any) => loan.loanGUID === historyLoan.loanGUID
      );
      if (loanIndex > -1) {
        this.checkedLoans.splice(loanIndex, 1);
      }
    }
    this.selectedFieldService.checkedHistoryLoans.next(this.checkedLoans);
    if (this.checkedLoans.length > 0) {
      this.dcService.sendButtonFlag.next(true);
    } else {
      this.dcService.sendButtonFlag.next(false);
    }
    console.log('Checked Loans', this.checkedLoans);
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  transmittedFileCount(files: any) {
    let counter = 0;
    if (files && files.length) {
      for (let file of files) {
        if (file.statusMessage.toLowerCase().includes('loan file exported successfully')) {
          counter++;
        }
      }
    }
    return counter;
  }

  pendingFileCount(files: any) {
    let counter = 0;
    if (files && files.length) {
      for (let file of files) {
        if (file.statusMessage.toLowerCase().includes('attachment ready for compress and merge')) {
          counter++;
        }
      }
    }
    return counter;
  }

  failedFileCount(element: any) {
    let counter = 0;
    if (element.files && element.files.length > 0) {
      for (let file of element.files) {
        if ((!file.statusMessage.toLowerCase().includes('loan file exported successfully')) &&
        (!file.statusMessage.toLowerCase().includes('attachment ready for compress and merge')))
         {
          counter++;
        }
      }
    }
    if (counter > 0) {
      element.status = "Unsuccessful";
    } 
    return counter;
  }

  exportToCSVStatusReport() {
    const dialogRef = this.dialog.open(CCMCConfirmDialogComponent, {
      data: 'Continue exporting status report to CSV?'
    });
    console.log('export to csv 703');
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let temp: any = [];

        if (this.exportButtonSwitch === false) {
          for (let row of this.dataSource.filteredData) {
            temp.push({
              loanNumber: row.loanNumber,
              processed: row.files.length,
              transmitted: this.transmittedFileCount(row.files),
              failed: this.failedFileCount(row.files)
            });
          }
        } else {
          for (let row of this.selectedLoan.files) {
            temp.push({
              fileName: row.fileName,
              source: row.source,
              destination: row.destination,
              statusMessage: row.statusMessage
            });
          }
        }

        console.log('Export to CSV');
        this.spinnerService.setShowSpinner(true);
        if (temp) {
          console.log(temp);
          let tempDoc = JSON.parse(JSON.stringify(temp));
          for (let value of tempDoc) {
            delete value.edited;
          }
          console.log(tempDoc);
          this.ccmcApiService
            .convertJSONToCSV(tempDoc)
            .subscribe(result => {
              console.log('export to csv 713');
              this.spinnerService.setShowSpinner(false);
              console.log(result);
              if (result) {
                const parsedResult = JSON.parse(JSON.stringify(result));
                if (parsedResult.statusFlag) {
                  var blob = new Blob([parsedResult.content], {
                    type: 'text/csv;charset=utf-8;'
                  });
                  var link = document.createElement('a');
                  if (link.download !== undefined) {
                    // feature detection
                    // Browsers that support HTML5 download attribute
                    var url = URL.createObjectURL(blob);
                    link.setAttribute('href', url);
                    link.setAttribute(
                      'download',
                      `${this.assetService.getSelectedFI()}-${this.assetService.getSelectedAssetId()}-StatusReport-${
                        new Date().toISOString()
                      }.csv`
                    );
                    link.style.visibility = 'hidden';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                  }
                } else {
                  const errorDialogRef = this.dialog.open(
                    ErrorDialogComponent,
                    {
                      data: {
                        title: 'Export Error',
                        message: parsedResult.statusMessage
                      }
                    }
                  );
                }
              }
            });
        }
      }
    });
  }

  clearSearchData() {
    this.filterSearchRef.nativeElement.value = '';
  }
}
